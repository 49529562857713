import React, { useEffect, useState } from "react";
// import $ from 'jquery'; 
import {Button} from "react-bootstrap";

const FieldsLinkerWrapper = (props) => {
  const [fieldLinks, setFieldLinks] = useState(null);

  useEffect(() => {
    console.log("props FieldsLinkerWrapper : ", typeof props.question);
    // Pastikan library FieldsLinker tersedia
    if (!window.$ || !window.$.fn.fieldsLinker) {
      console.error("FieldsLinker atau jQuery tidak ditemukan.");
      return;
    }

    // Data input untuk FieldsLinker
    const input = {
      options: {
        associationMode: "oneToOne", // oneToOne,manyToMany
        lineStyle: "square-ends",
        buttonErase: "Erase Links",
        displayMode: "original",
        whiteSpace: window.$("input[name='whiteSpace']:checked").val(), //normal,nowrap,pre,pre-wrap,pre-line,break-spaces default => nowrap
        mobileClickIt: true,
      },
      Lists: [
        {
          name: "Pertanyaan",
          list: props.question,
        },
        {
          name: "Jawaban",
          list: props.answer,
        },
      ],
      existingLinks: props.existingAnswer,
      onChange: (bonds) => {
        console.log("Updated Links:", bonds);
      },
    };

    console.log("input", input);
    window.$(document).ready(function( $ ) {

        const fieldLinks = window.$(".bonds").fieldsLinker("init", input);

        setFieldLinks(fieldLinks);
    
        // Bersihkan instance FieldsLinker saat komponen dilepas
        return () => {
          fieldLinks.fieldsLinker("destroy");
        };
    
      
      });
    // Inisialisasi FieldsLinker
  }, [props.question]);

  const simpan = () => {
    console.log("wkwkwk");
    console.log(fieldLinks.fieldsLinker("getLinks"));
    props.onChange(fieldLinks.fieldsLinker("getLinks"));
  };

  return (
    <div>
      {/* <h1>Fields Linker Example {JSON.stringify(props.question)}</h1> */}

      {/* {question.map((item, index) => (
        <li key={index}>
          {item.label}: {item.value}
        </li>
      ))} */}
      {/* Elemen untuk FieldsLinker */}
      <div className="bonds" id="original"></div>

      <Button className="me-2 mt-2" variant="primary btn-md" onClick={() => simpan()}>Simpan</Button>
    </div>
  );
};


export default FieldsLinkerWrapper;