import React, {Fragment, useEffect, useState} from "react";
import PageTitle from "../../layouts/PageTitle";
import {Badge, Button, Card, Col, Row, Offcanvas} from "react-bootstrap";
import {Text} from "recharts";
import axiosInstance from "../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import SMEditor from "../Editor";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import './UjianProcess.css';
import imageCompression from "browser-image-compression";
import FieldsLinkerWrapper from "../FieldsLinker";
function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const imageFile = await loader.file;
            if (!imageFile) return;

            console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
            // setOriginalFileSize((imageFile.size / 1024 / 1024).toFixed(2));

            const options = {
            maxSizeMB: 1.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            };

            const compressedFile = await imageCompression(imageFile, options);
            console.log(
                "compressedFile instanceof Blob",
                compressedFile instanceof Blob
            ); // true
            console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            ); // smaller than maxSizeMB
            // setCompressedFileSize((compressedFile.size / 1024 / 1024).toFixed(2));

            // const file = await loader.file;
            console.log('compressedFile : ', compressedFile);
            const response = await axiosInstance.request({
              method: "POST",
              url: `media/upload`,
              data: {
                file: compressedFile
              },
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });
            console.log(response);
            resolve({
              default: `${response.data.url}`
            });
          } catch (error) {
            reject(JSON.stringify(error));
          }
        });
      },
      abort: () => {}
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      console.log("loader : ", loader);
      return uploadAdapter(loader);
    };
  }

const UjianProcess = () => {
    const navigate = useNavigate();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [show, setShow] = useState(false);

    const togglePanel = () => setShow(!show);

    useEffect(() => {
        const handleResize = () => {
        const width = window.innerWidth;

        // Periksa apakah layar adalah mobile atau tablet
        setIsMobile(width < 768);
        setIsTablet(width >= 768 && width <= 1024);
        };

        // Jalankan sekali saat komponen di-mount
        handleResize();

        // Tambahkan event listener untuk resize
        window.addEventListener('resize', handleResize);

        // Hapus event listener saat komponen di-unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const [durasi, setDurasi] = useState(0);
    const [ujianContent, setUjianContent] = useState([]);
    const [indexSoal, setIndexSoal] = useState(0);
    const [answer, setAnswer] = useState([]);
    const [jumlahSoal, setJumlahSoal] = useState(0);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const [codeList, setCodeList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [answerList, setAnswerList] = useState([]);
    const [existingAnswer, setExistingAnswer] = useState([]);
    useEffect(() => {
        if((localStorage.getItem('id') == null || localStorage.getItem('id') == undefined  || localStorage.getItem('id') != id) || localStorage.getItem(`durasi-${id}`) == null || localStorage.getItem('detailUjian') == null || localStorage.getItem('jawaban') == null || localStorage.getItem('jumlahSoal') == null){
            getSoalData()
        }else{
            setDurasi(localStorage.getItem(`durasi-${id}`))
            setUjianContent(JSON.parse(localStorage.getItem('detailUjian')))
            if(localStorage.getItem('jawaban') != null){
                setAnswer(JSON.parse(localStorage.getItem('jawaban')))
            }
            setJumlahSoal(localStorage.getItem('jumlahSoal'))

            //PJ
            setQuestionList(JSON.parse(localStorage.getItem('questionList')));
            setAnswerList(JSON.parse(localStorage.getItem('answerList')));
            setCodeList(JSON.parse(localStorage.getItem('codeList')));

            var tmpExistAnswer = [];
            var tmpquestionList = JSON.parse(localStorage.getItem('questionList'));
            var tmpanswer = JSON.parse(localStorage.getItem('jawaban'));
            var tmpcodeList = JSON.parse(localStorage.getItem('codeList'));
            for(var i = 0; i < tmpquestionList.length; i++){
                var code = tmpcodeList[i];
                console.log("code : ", code)
                tmpExistAnswer.push({from: tmpquestionList[i], to: tmpanswer[code]});
            }

            setExistingAnswer(tmpExistAnswer)
        }
        
    }, [])

    const shuffle = (item) => {
        return item.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    };

    const getSoalData = async () => {
        try {
            const res = await axiosInstance.get(`test-online/siswa/ujian/content/${id}`);
            console.log(res);
            localStorage.setItem('id', id)
            localStorage.setItem('detailUjian', JSON.stringify(res.data.data));
            if(localStorage.getItem(`durasi-${id}`) == null || localStorage.getItem(`durasi-${id}`) == undefined){
                localStorage.setItem(`durasi-${id}`, res.data.data.detail.durasi)
            }
            
            var obj = {};
            var jumlah = 0;
            var tmpQuestion = [];
            var tmpAnswer = [];
            var tmpCode = [];
            for(var i = 0; i < res.data.data.value.length; i++){
                
                obj[res.data.data.value[i].code] = res.data.data.value[i].value.jawaban
                if(res.data.data.value[i].type == "MPG"){
                    for(var a = 0; a < res.data.data.value[i].value.soal.length; a++){
                        obj[res.data.data.value[i].value.soal[a].code] = res.data.data.value[i].value.soal[a].jawaban;
                        jumlah++;
                    }
                }

                //PJ
                
                if(res.data.data.value[i].type == "PJ"){
                    tmpQuestion.push(res.data.data.value[i].value.pertanyaan);
                    tmpAnswer.push(res.data.data.value[i].value.pasangan);
                    if(res.data.data.value[i].value.pengecoh != null){
                        tmpAnswer.push(res.data.data.value[i].value.pengecoh);
                    }
                    tmpCode.push(res.data.data.value[i].code);
                }
                //ENDPJ
                jumlah++;
            }

            // PJ
            tmpAnswer = shuffle(tmpAnswer);
            localStorage.setItem('questionList', JSON.stringify(tmpQuestion));
            localStorage.setItem('answerList', JSON.stringify(tmpAnswer));
            localStorage.setItem('codeList', JSON.stringify(tmpCode));
            setQuestionList(tmpQuestion);
            setAnswerList(tmpAnswer);
            setCodeList(tmpCode);

            var tmpExistAnswer = []
            for(var i = 0; i < tmpQuestion.length; i++){
                var code = tmpCode[i];
                tmpExistAnswer.push({from: tmpQuestion[i], to: tmpAnswer[code]});
            }

            setExistingAnswer(tmpExistAnswer)
            // END PJ


            localStorage.setItem('jawaban', JSON.stringify(obj))
            localStorage.setItem('jumlahSoal', jumlah)
            setAnswer(obj)
            setJumlahSoal(jumlah)
            setDurasi(res.data.data.detail.durasi)

            setUjianContent(res.data.data);

        } catch (error) {
            console.log("INI ERROR", error);
            if (error.code == "ERR_BAD_REQUEST") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${error.response.data.message}`,
                    willClose: () => {
                        navigate('/ujian/list');
                    }
                })
            }
            
            // navigate(-1);
        }
    }

    useEffect(() => {
        console.log("existingAnswer : ", existingAnswer)
    }, [existingAnswer])
    const numbers = Array.from({ length: ujianContent?.value?.length }, (_, i) => i + 1);
    const [existTimerId, setExistTimerId] = useState(null);
    useEffect(() => {
        if (durasi > 0) {
          const timerId = setInterval(() => {
            setDurasi((prevSeconds) => prevSeconds - 1);
            localStorage.setItem(`durasi-${id}`, durasi - 1)
            setExistTimerId(timerId);
          }, 1000);
          
          return () => clearInterval(timerId); // Cleanup on unmount
        }

        if(durasi == 1){
            kirimJawabanKetikaSelesai();
        }
      }, [durasi]);

      const kirimJawabanKetikaSelesai = async () => {
        setSubmitButtonDisabled(true);
        var params = answer;

        var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, params);

        var res = await axiosInstance.put(`test-online/siswa/ujian/finish/${id}`);

        setTimeout(() => {
            setSubmitButtonDisabled(false);
            clearInterval(existTimerId);
            localStorage.setItem(`durasi-${id}`, 0);
            setDurasi(0);
            localStorage.clear();

            Swal.fire({
                icon: 'success',
                title: 'Waktu Ujian Berakhir, Jawaban Berhasil dikirim',
                willClose: () => {
                    navigate('/ujian/list');
                }
            })
        }, 1000);
      }
    
      const formatTime = (totalSeconds) => {
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const secs = String(totalSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
      };

    const handleAnswer = async (code, key) => {
        console.log("key : ", key)
        console.log("code : ", code)
        var jawaban = answer;
        console.log("jawaban list : ", jawaban)
        jawaban[code] = key;
        console.log("jawaban code : ", jawaban[code])
        console.log("jawaban : ", jawaban)
        setAnswer(jawaban);
        console.log("jawaban : ", jawaban)
        localStorage.setItem('jawaban', JSON.stringify(jawaban))

        var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, jawaban);

    }

    const [isTyping, setIsTyping] = useState(false);
    const [tmpUraian, setTmpUraian] = useState("");
    const handleAnswerUraian =  (code, key) => {
        // setIsTyping(true);
        console.log("key : ", key)
        console.log("code : ", code)
        // var jawaban = answer;
        // console.log("jawaban list : ", jawaban)
        answer[code] = key;
        // console.log("jawaban code : ", jawaban[code])
        // console.log("jawaban : ", jawaban)
        setAnswer(answer);
        setIsTyping(false);
        // console.log("jawaban : ", jawaban)
        // localStorage.setItem('jawaban', JSON.stringify(jawaban))

        // var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, jawaban);
    }

    const handleAnswerUraianKirim = async (code, key) => {
        
        console.log("key : ", key)
        console.log("code : ", code)
        var jawaban = answer;
        console.log("jawaban list : ", jawaban)
        jawaban[code] = key;
        console.log("jawaban code : ", jawaban[code])
        console.log("jawaban : ", jawaban)
        setAnswer(jawaban);
        console.log("jawaban : ", jawaban)
        localStorage.setItem('jawaban', JSON.stringify(jawaban))

        var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, jawaban);
        Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Jawaban berhasil dikirim',
        })
        setTmpUraian("");
    }

    const handleKirimJawaban = async () => {
        Swal.fire({
            title: "Anda yakin?",
            text: "Dengan menekan tombol kirim, jawaban akan dikirim dan ujian akan diakhiri.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya, Kirim Jawaban dan Akhiri Ujian"
          }).then(async (result) => {
            if (result.isConfirmed) {
                setSubmitButtonDisabled(true);
                var params = answer;

                var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, params);

                var res = await axiosInstance.put(`test-online/siswa/ujian/finish/${id}`);

                setTimeout(() => {
                    setSubmitButtonDisabled(false);
                    clearInterval(existTimerId);
                    localStorage.setItem(`durasi-${id}`, 0);
                    setDurasi(0);
                    localStorage.clear();

                    Swal.fire({
                        icon: 'success',
                        title: 'Jawaban Berhasil dikirim',
                        willClose: () => {
                            navigate('/ujian/list');
                        }
                    })
                }, 1000);
            }
          });
    }

    const handleSimpanPJ = async (value) => {
        console.log("value : ", value)
        var jawabanPJ = value.links;

        for(var i = 0; i < jawabanPJ.length; i++){
            var index = questionList.indexOf(jawabanPJ[i].from);
            var code = codeList[index];
            answer[code] = jawabanPJ[i].to;
            setAnswer(answer);
            localStorage.setItem('jawaban', JSON.stringify(answer))
        }

        var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, answer);
        Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Jawaban berhasil dikirim',
        })
    }
      
    return (
        <Fragment>
            <PageTitle activeMenu="ujian" motherMenu="ujian"/>
            <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
                {ujianContent && <Row>
                    <Col xl={12}>
                        <Card name="default-tab" className="dz-card p-3">
                            <Card.Header className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <Card.Title>{ ujianContent?.detail?.mata_pelajaran }</Card.Title>
                                </div>
                                <div>
                                    <Card.Text>Waktu Tersisa : {formatTime(durasi)}</Card.Text>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {/* {ujianContent.value && (ujianContent.value[indexSoal].type)} */}
                                <Row lg={12}>
                                    {ujianContent.value && (ujianContent.value[indexSoal].type != "MPG" && ujianContent.value[indexSoal].type != "UR" && ujianContent.value[indexSoal].type != "PJ") && <Col lg={8}> 
                                        <div className="email-left-box dlab-scroll  pt-3" style={{height: '100%'}}>
                                            <Row className="mb-2">
                                                <Col lg={12} className="d-flex gap-3" style={{alignItems: 'baseline'}}>
                                                    <div><strong>{indexSoal + 1}.</strong> </div>
                                                    <div dangerouslySetInnerHTML={{__html: ujianContent?.value[indexSoal].value.pertanyaan}}></div>
                                                </Col>
                                            </Row>
                                            {Object.keys(ujianContent.value[indexSoal].value.pilihan).map((key, index)=>(
                                                <Row className="mb-1" key={index}>
                                                    <Col lg={12} className="d-flex flex-wrap col-lg-12 gap-2" style={{alignItems: 'baseline'}}>
                                                        <Badge onClick={() => handleAnswer(ujianContent.value[indexSoal].code, key)} as="a" bg={ (answer[ujianContent.value[indexSoal].code] == key ? "success" : "secondary") + " badge-circle"}>
                                                            {key}
                                                        </Badge>
                                                        {/* <span>&nbsp; {ujianContent.value[indexSoal].value.pilihan[key]}</span> */}
                                                        <div dangerouslySetInnerHTML={{__html: ujianContent.value[indexSoal].value.pilihan[key]}}></div>
                                                    </Col>
                                                </Row>
                                            ))}
                                            
                                        </div>
                                    </Col>}
                                    {ujianContent.value && (ujianContent.value[indexSoal].type == "UR") && <Col lg={8}> 
                                        <div className="email-left-box dlab-scroll  pt-3" style={{height: '100%'}}>
                                            <Row className="mb-2">
                                                <Col lg={12}  className="d-flex gap-3" style={{alignItems: 'baseline'}}>
                                                    <div><strong>{indexSoal + 1}.</strong> </div>
                                                    <div dangerouslySetInnerHTML={{__html: ujianContent?.value[indexSoal].value.pertanyaan}}></div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col lg={12} className="justify-content-between flex-wrap">
                                                
                                                <CKEditor
                                                    config={{
                                                        // plugins: [PasteFromOffice],
                                                        toolbar: {
                                                        items: [
                                                            // 'undo', 'redo',
                                                            // '|',
                                                            // 'heading',
                                                            // '|',
                                                            // 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                            // '|',
                                                            // 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                                            // '|',
                                                            'uploadImage'
                                                            // '|',
                                                            // 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                                        ],
                                                        shouldNotGroupWhenFull: false
                                                        },
                                                        placeholder: "Tuliskan Jawaban di sini...",
                                                        extraPlugins: [uploadPlugin],
                                                        
                                                        // toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo']
                                                    }}
                                                    editor={ClassicEditor}
                                                    data={isTyping ? tmpUraian : ((answer[ujianContent.value[indexSoal].code] != null && answer[ujianContent.value[indexSoal].code] != undefined) ? answer[ujianContent.value[indexSoal].code] : "")}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setIsTyping(true);
                                                        setTmpUraian(data)
                                                    }}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData();
                                                        // handleSoalChange(groupIndex, data);
                                                        handleAnswerUraian(ujianContent.value[indexSoal].code, data)
                                                    }}
                                                    // onChange={(event, editor) => {
                                                    //   const data = editor.getData();
                                                    //   handleSoalChange(groupIndex, data);
                                                    // }}
                                                    />
                                                {/* <textarea
                                                    name="ok"
                                                    className="form-control"
                                                    id={ujianContent.value[indexSoal].code}
                                                    value={isTyping ? tmpUraian : answer[ujianContent.value[indexSoal].code]}
                                                    key={ujianContent.value[indexSoal].code}
                                                    onChange={(e) => {
                                                        setIsTyping(true);
                                                        setTmpUraian(e.target.value)
                                                    }}
                                                    onBlur={(e) => handleAnswerUraian(ujianContent.value[indexSoal].code, e.target.value)}
                                                    // onBlur={(e) => handleAnswerUraian(ujianContent.value[indexSoal].code, e.target.value)}
                                                    ></textarea> */}
                                                <Button className="me-2 mt-2" variant="primary btn-md" onClick={() => handleAnswerUraianKirim(ujianContent.value[indexSoal].code, answer[ujianContent.value[indexSoal].code])}>Simpan</Button>
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                    </Col>}
                                    {ujianContent.value && (ujianContent.value[indexSoal].type == "MPG") && <Col lg={8}> 
                                        <div className="email-left-box dlab-scroll  pt-3" style={{height: '100%'}}>
                                            <Row className="mb-2">
                                                <Col lg={12}>
                                                    <p>{ujianContent?.value[indexSoal].value.content}</p>
                                                </Col>
                                            </Row>
                                            {ujianContent?.value[indexSoal].value.soal.map((key, index)=>(
                                                <div className="mt-3">
                                                    <Row className="mb-2">
                                                        {/* <Col lg={12}>
                                                            <p><strong>{indexSoal + 1 + index}.</strong> {ujianContent?.value[indexSoal].value.soal[index].pertanyaan}</p>
                                                        </Col> */}
                                                        <Col lg={12} className="d-flex gap-3" style={{alignItems: 'baseline'}}>
                                                            <div><strong>{indexSoal + 1 + index}.</strong> </div>
                                                            <div dangerouslySetInnerHTML={{__html: ujianContent?.value[indexSoal].value.soal[index].pertanyaan}}></div>
                                                        </Col>
                                                    </Row>
                                                    {Object.keys(ujianContent.value[indexSoal].value.soal[index].pilihan).map((key, indeks)=>(
                                                        <Row className="mb-1" key={indeks}>
                                                            <Col lg={12} className="d-flex flex-wrap col-lg-12 gap-2" style={{alignItems: 'baseline'}}>
                                                                <Badge onClick={() => handleAnswer(ujianContent.value[indexSoal].value.soal[index].code, key)} as="a" bg={ (answer[ujianContent.value[indexSoal].value.soal[index].code] == key ? "success" : "secondary") + " badge-circle"}>
                                                                    {key}
                                                                </Badge>
                                                                <div dangerouslySetInnerHTML={{__html: ujianContent.value[indexSoal].value.soal[index].pilihan[key]}}></div>
                                                                {/* <span>&nbsp; {ujianContent.value[indexSoal].value.soal[index].pilihan[key]}</span> */}
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ))}                                            
                                        </div>
                                    </Col>}
                                    {ujianContent.value && (ujianContent.value[indexSoal].type == "PJ" && questionList && answerList) && <Col  xs={12} lg={8}>
                                         <FieldsLinkerWrapper question={questionList} answer={answerList} existingAnswer={existingAnswer} onChange={(e) => handleSimpanPJ(e)} />
                                    </Col>}
                                    {(!isMobile && !isTablet) && <Col lg={4} className="">
                                    <div className="email-right-box mr-1">
                                        <Row>
                                        {answer && Object.keys(answer).map((key, index)=>(
                                           
                                                <Col lg={2} key={index  }>
                                                <Button onClick={() => setIndexSoal(index)} style={{"width": "50px"}} className="m-1" variant={ (answer[key] == null || answer[key] == '' ? "primary" : answer[key] == 'ragu' ? "warning" : "success") + " btn-sm"}>
                                                    {index + 1}
                                                </Button>
                                                </Col>
                                            
                                        ))}
                                        </Row>

                                    </div>
                                    </Col>}
                                    {(isMobile || isTablet) && !show && <Button
                                        onClick={togglePanel}
                                        style={{
                                            position: 'fixed',
                                            top: '25%',
                                            right: '-50px',
                                            transform: 'rotate(-90deg)',
                                            backgroundColor: 'rgb(66, 103, 178)',
                                            color: 'rgb(255, 255, 255)',
                                            border: 'medium',
                                            zIndex: 1050,
                                            transformOrigin: 'bottom',
                                            writingMode: 'sideways-rl',
                                            height: '32px',
                                            padding: '10px 15px',
                                            width: '100px'
                                        }}
                                        >
                                        SOAL
                                        </Button>}
                                    <Offcanvas show={show} onHide={togglePanel} placement="end" style={{ width: '75%' }}>
                                        <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>Daftar Soal</Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                        <div className="d-flex flex-wrap">
                                            {answer && Object.keys(answer).map((key, index)=>(
                                            // <Button
                                            //     key={index + 1}
                                            //     className="m-1"
                                            //     style={{
                                            //     width: '60px',
                                            //     height: '60px',
                                            //     backgroundColor: '#28a745',
                                            //     border: 'none',
                                            //     color: '#fff',
                                            //     }}
                                            // >
                                            //     {index + 1}
                                            // </Button>
                                            <Button key={index + 1} onClick={() => setIndexSoal(index)} style={{"width": "50px", "height": '60px'}} className="m-1" variant={ (answer[key] == null || answer[key] == '' ? "primary" : answer[key] == 'ragu' ? "warning" : "success") + " btn-sm"}>
                                                    {index + 1}
                                                </Button>
                                            ))}
                                        </div>
                                        </Offcanvas.Body>
                                    </Offcanvas>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col>
                                    {indexSoal > 0 && <Button className="me-2" variant="primary btn-md" onClick={() => {setIndexSoal(indexSoal - 1)}}>
                                    Sebelumnya
                                </Button>}
                                <Button className="me-2" variant="warning btn-md" onClick={() => handleAnswer(ujianContent.value[indexSoal].code, '')}>
                                    Ragu-Ragu
                                </Button>
                                {indexSoal < jumlahSoal - 1 && <Button className="me-2" variant="primary btn-md" onClick={() => {setIndexSoal(indexSoal + 1)}}>
                                    Selanjutnya
                                </Button>}
                                    </Col>
                                    <Col>
                                    <Button className="me-2" variant="danger btn-md" onClick={() => handleKirimJawaban()} disabled={submitButtonDisabled}>
                                    {submitButtonDisabled ? 'Menyimpan Jawaban...' : 'Selesai Ujian'}
                                </Button>
                                    </Col>
                                </Row>
                                

                                
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>}
            </div>
        </Fragment>
);
}

export  default UjianProcess;