var HOST = '';
var MAU_URL = '';
if (process.env.NODE_ENV === 'development') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
    MAU_URL = 'https://maudev.schoolmedia.my.id';
}
if (process.env.NODE_ENV === 'staging') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
    MAU_URL = 'https://maudev.schoolmedia.my.id';
}
if (process.env.NODE_ENV === 'production') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
    MAU_URL = 'https://maudev.schoolmedia.my.id';
}

export const HOSTNAME = HOST;
export const MAUURL = MAU_URL;